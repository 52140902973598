<template>
  <div>
    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn rounded disabled>{{ currentCenter.name }}</v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="ml-4">
          <table id="customers">
            <tr>
              <td><p>Organization Name</p></td>
              <td>
                <p>{{ storeOrganization.name }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Organization Type</p></td>
              <td>
                <p>{{ storeOrganization.type }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Main Activity</p></td>
              <td>
                <p>{{ storeOrganization.mainActivities }}</p>
              </td>
            </tr>
            <tr>
              <td><p>District</p></td>
              <td>
                <p>{{ storeOrganization.locationName }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Address</p></td>
              <td>
                <p>{{ storeOrganization.address }}</p>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="ml-4">
          <table id="customers">
            <tr>
              <td><p>Representative Name</p></td>
              <td>
                <p>{{ storeOrganization.name }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Position</p></td>
              <td>
                <p>{{ storeOrganization.contactPosition }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Contact Phone</p></td>
              <td>
                <p>{{ storeOrganization.contactPhone }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Contact Email</p></td>
              <td>
                <p>{{ storeOrganization.contactEmail }}</p>
              </td>
            </tr>
            <tr>
              <td><p>Total Graduates</p></td>
              <td>
                <p>{{ storeOrganization.graduands.content.length }}</p>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="graduates"
        :page.sync="page"
        :items-per-page="delinquentsPerPage"
        :server-items-length="totalDelinquents"
        hide-default-footer
        @page-count="pageCount = $event"
        class="elevation-1 ml-4"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-dialog v-model="dialog" max-width="1200px" persistent>
              <template v-slot:activator="{ on }">
                <v-spacer></v-spacer>
                <v-btn color="green" dark class="mb-2" v-on="on" small right
                  >Add Graduate</v-btn
                >
              </template>
              <v-card>
                <validation-observer ref="form">
                  <form @submit.prevent="onSubmit">
                    <v-card-title class="mb-12">
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Occupation"
                            >
                              <p class="font-weight-thin">Add Graduates</p>
                              <multiselect
                                v-model="selected"
                                placeholder="Select"
                                :options="delinquents"
                                :multiple="true"
                                :hideSelected="true"
                                :group-select="true"
                                track-by="groupedId"
                                :error-messages="errors"
                                label="groupedId"
                              ></multiselect>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Occupation"
                            >
                              <v-text-field
                                v-model="occupation"
                                label="Occupation/Position"
                                outlined
                                name="Occupation"
                                :error-messages="errors"
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Occupation"
                            >
                              <v-menu
                                ref="joinOrganizationDateMenu"
                                v-model="joinOrganizationDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="formattedJoinOrganizationDate"
                                    label="Join Date"
                                    outlined
                                    :error-messages="errors"
                                    dense
                                    v-if="selectDate"
                                    readonly
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="joinOrganizationDate"
                                  :max="new Date().toISOString().substr(0, 10)"
                                  min="1900-01-01"
                                  no-title
                                  @change="saveJoinOrganizationDate"
                                ></v-date-picker>
                              </v-menu>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="dialogbox">
                        <v-btn
                          color="green"
                          dark
                          class="ml-12 mt-n10"
                          type="submit"
                          >Save</v-btn
                        >
                        <v-btn class="ml-4 mt-n10" @click="close">Cancel</v-btn>
                      </div>
                    </v-card-actions>
                  </form>
                </validation-observer>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.names="{ item }">
          <span class="btn blue--text"
            >{{ item.delinquent.firstName }}
            {{ item.delinquent.lastName }}</span
          >
        </template>
        <template v-slot:item.age="{ item }">
          {{
            parseInt(
              $moment(new Date().toISOString().substr(0, 10)).format("YYYY")
            ) - parseInt($moment(item.delinquent.dateOfBirth).format("YYYY"))
          }}
        </template>
        <template v-slot:item.occupation="{ item }">
          {{ item.occupations[0].name }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="traineeDialog(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      joinOrganizationDialog: false,
      selectDate: true,
      names: null,
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      headers: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'no'
        },
        { text: 'Names', value: 'names' },
        { text: 'Age', value: 'age' },
        { text: 'Graduation Date', value: 'graduationDate' },
        { text: 'Graduation Center', value: 'center' },
        { text: 'Occupation', value: 'occupation' },
        { text: 'Guardian Name', value: 'delinquent.guardian' },
        { text: 'Guardian Phone', value: 'delinquent.guardianPhone' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      delinquents: [],
      enrolments: [],
      selected: null,
      editedTraineeIndex: -1,
      delinquentItem: null,
      transferId: null,
      errors: null,
      traineeIndex: null,
      traineeItem: null,
      occupation: null,
      edit: false,
      editedTrainee: {
        enrolmentState: null,
        enrolmentStateReason: null,
        trainee: {
          latestTransfer: {
            id: null
          }
        }
      },
      defaultTraineeItem: {
        enrolmentState: null,
        enrolmentStateReason: null,
        trainee: {
          latestTransfer: {
            id: null
          }
        }
      },
      graduates: [],
      delinquentFirstName: null,
      delinquentLastName: null,
      placeOfBirthId: null,
      residenceId: null,
      recordDates: null,
      arrivalDates: null,
      exitDates: null,
      released: null,
      states: null,
      eligibilityStates: null,
      intakeId: null,
      intakeSet: null,
      decisionIds: null,
      physicallyScreened: null,
      screeningApprovalStatuses: null,
      onGraduationList: null,
      graduationId: null,
      exapnd: null,
      graduationApprovalStatuses: null,
      postRehabDestinationId: null,
      hasOccupation: null,
      organizationId: null,
      sort: 'recordDate,desc',
      joinOrganizationDate: new Date().toISOString(),
      joinOrganizationDateMenu: false
    }
  },
  components: {
    Multiselect
  },

  computed: {
    formTitle () {
      return this.editedTraineeIndex === -1 ? 'Add Graduate' : 'Edit Graduate'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'storeOrganization'
    ]),
    formattedJoinOrganizationDate () {
      return this.joinOrganizationDate
        ? this.$moment(this.joinOrganizationDate).format('DD-MMM-YYYY')
        : this.retrievedJoinOrganizationDate
          ? this.$moment(this.retrievedJoinOrganizationDate).format('DD-MMM-YYYY')
          : ''
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  mounted () {
    this.initialize()
    this.loadAllDelinquents()
    this.checkPrivileges()
  },
  created () {
    this.privileges = this.currentUser.privileges
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    initialize () {
      let index = 0
      this.storeOrganization.graduands.content.forEach(el => {
        index++
        el.no = index
        this.graduates.push(el)
      })
      this.totalDelinquents = this.storeOrganization.graduands.totalElements
    },
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadAllDelinquents () {
      const pageNumber = this.page - 1
      this.hasOccupation = false
      this.released = true
      this.states = 'GRADUATED'
      this.$delinquentService
        .fetchAllTransfersSpecification(
          this.delinquentFirstName,
          this.delinquentLastName,
          this.placeOfBirthId,
          this.residenceId,
          this.recordDates,
          this.arrivalDates,
          this.exitDates,
          this.released,
          this.states,
          this.eligibilityStates,
          this.intakeId,
          this.intakeSet,
          this.decisionIds,
          this.physicallyScreened,
          this.screeningApprovalStatuses,
          this.onGraduationList,
          this.graduationId,
          this.exapnd,
          this.graduationApprovalStatuses,
          this.postRehabDestinationId,
          this.hasOccupation,
          this.organizationId,
          pageNumber,
          this.delinquentsPerPage,
          this.sort
        )
        .then((response) => {
          if (!response.status) {
            this.delinquents = response.content
            this.delinquents.forEach((element) => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.namesDash =
                element.delinquent.firstName +
                '-' +
                element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(
                element.delinquent.dateOfBirth
              ).format('DD-MMM-YYYY')
              element.date = this.$moment(
                element.delinquent.dateOfBirth
              ).format('YYYY')
              element.groupedId =
                element.namesDash +
                '-' +
                element.date +
                '-' +
                element.delinquent.placeOfBirth.name
              element.arrivalDate = this.$moment(element.arrivalDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    joinOrganization () {
      const joinObject = {
        name: this.occupation,
        startDate: this.formattedJoinOrganizationDate,
        transferIds: this.selected.map((element) => element.id)
      }
      this.$delinquentService
        .addGraduatesToOrganization(this.storeOrganization.id, joinObject)
        .then((response) => {
          if (!response.message) {
            this.graduates.push(response)
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    removeGraduateFromOrganization () {
      this.deleteDialog = false
      this.$delinquentService
        .removeGraduateFromOrganization(
          this.storeOrganization.id,
          this.traineeItem.trainee.latestTransfer.id
        )
        .then((response) => {
          if (response.status === 'success') {
            this.graduates.splice(this.traineeIndex, 1)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    onSubmit () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        this.joinOrganization()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },

    close () {
      this.edit = false
      this.dialog = false
      setTimeout(() => {
        this.editedTrainee = Object.assign({}, this.defaultTraineeItem)
        this.editedTraineeIndex = -1
      }, 300)
    },
    saveJoinOrganizationDate (joinOrganizationDate) {
      this.$refs.joinOrganizationDateMenu.save(joinOrganizationDate)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style scoped>
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers td p {
  margin-left: 30px;
}

#customers td:nth-child(odd) {
  font-weight: bold;
  width: 50%;
}

#customers td:nth-child(even) {
  width: 50%;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

.dialogbox {
  margin-bottom: 200px;
}
.inlinetables {
  display: inline;
}
</style>
